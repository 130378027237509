<template>
  <div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="Acknowledgment"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-content-width="800px"
      ref="html2Pdf"
      pdf-orientation="portrait"
      margin="10"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
    >
      <section slot="pdf-content">
        <div style="width:100%">
          <section class="working-area py-4">
            <div class="my-4">
              <p class="body-2">
                {{ formatDate(customerInformation.createdAt) }}
              </p>
            </div>
            <div class="my-2">
              <p class="body-2">
                {{ customerInformation.firstName }}
                {{ customerInformation.lastName }} <br />
                {{ customerInformation.address[0].addressLine1 }} <br />
                {{ customerInformation.address[0].addressLine2 }},
                {{ customerInformation.address[0].pincode }}
              </p>
            </div>
            <div class="my-4">
              <p class="body-2">
                Re: <strong>Soft-Approval (Acknowledgment Letter)</strong>
              </p>
            </div>
            <div class="my-10">
              <p class="body-2">
                <span v-if="customerInformation.gender === 'Male'"
                  >Dear Sir,</span
                >
                <span v-else-if="customerInformation.gender === 'Female'"
                  >Dear Madam,</span
                >
                <span v-else>Dear Sir/Madam,</span>
              </p>
              <p class="body-2">
                Congratulations, <br />
                We have accepted your request for Loan with reference Number :
                <strong>{{ customerInformation.applicationId }}</strong>
              </p>
              <p class="body-2">
                You have been soft approved with a loan amount of
                {{ loanInformation.ScorecardEligibleAmount | currency }} for
                purchase of {{ assetInformation.model }}
                {{ assetInformation.modelNumber }} ({{
                  assetInformation.productSku
                }}). Here your monthly EMI will be
                {{ loanInformation.product[0].EMIAmount | currency }} for next
                {{ loanInformation.product[0].netTenor }} Months. You can now
                travel to dealership with confidence and walk out with your
                bike.
              </p>
              <p class="body-2">Ontrack Recommended Dealership :</p>
              <p class="ma-0 pa-0 body-2">
                <strong>{{ preferredDealer.dealer.name }}</strong>
              </p>
              <p class="ma-0 pa-0 body-2">
                {{ preferredDealer.dealer.address }}
              </p>
              <p class="ma-0 pa-0 body-2">
                Ph (Dealership): {{ preferredDealer.dealer.dContact }}
              </p>
              <p class="ma-0 pa-0 body-2">
                Ph (Financer): {{ preferredDealer.dealer.idfcContact || " - " }}
              </p>
            </div>
            <div class="my-4 mt-15">
              <p class="body-2">
                We recommend to take a print of this letter during your visit,
                along with the following documents.
              </p>
              <ul>
                <li class="body-2">Passport Size Photo (Two)</li>
                <li class="body-2">Aadhaar Card</li>
                <li class="body-2">
                  Govt Authorised ID proof - (DL, Voter ID) / Rental agreement
                </li>
                <li class="body-2">Bank Pass book (optional)</li>
                <li class="body-2">
                  Gas Bill /Electricity Bill (in case of owned house)
                </li>
                <li class="body-2">
                  Payslip of at least 3 months in case of Salaried Professionals
                  & 12 months in case of Self-Employed.
                </li>
              </ul>
            </div>
            <div class="my-4 pa-0 ma-0">
              <v-col class="logo ma-0 pa-0">
                <img width="120px" :src="logo" alt="" />
              </v-col>
              <p class="body-1 ma-0 pa-0">
                <strong>Ontrack Technologies Pvt Ltd</strong>
              </p>
              <p class="ma-0 pa-0 body-2 text--disabled">
                3rd Floor, 9th Avenue Building, Indiranagar, Bangalore 560008
              </p>
            </div>
          </section>
        </div>
        <div class="html2pdf__page-break" />
        <div style="width:100%">
          <section class="working-area py-4">
            <p class="my-2 pa-0 body-2">
              <strong>Terms & Conditions</strong>
            </p>
            <ol class="mt-4">
              <li class="body-2">
                Ontrack is a distributor of two-wheeler loans for certain banks
                and NBFCs.
              </li>
              <li class="body-2">
                Ontrack Finance is an offering on the Ontrack Applications and
                Websites through which Ontrack facilitates provision of loans
                from the lenders to its users.
              </li>
              <li class="body-2">
                Ontrack is merely a facilitator and not a lender to its users.
              </li>
              <li class="body-2">
                Users hereby agree that the users’ credit bureau information on
                a non-individually identifiable basis may be shared with the
                Lenders.
              </li>
              <li class="body-2">
                Based on the User’s credit information and on the Lender’s
                preliminary lending criteria, Ontrack may show loan offerings to
                the user. Provided however, Ontrack may choose to introduce,
                vary or withdraw such offers at any time and Ontrack does not
                guarantee or assure any user of loan offerings being available
                to them.
              </li>
              <li class="body-2">
                The On-Road-Price are indicative and actual prices may vary at
                the dealership.
              </li>
              <li class="body-2">
                Selection of Model, Color and Variant are for understanding loan
                requirements only, and further selection to be made at the
                dealership.
              </li>
              <li class="body-2">
                Bikes are subject to availability at the dealership, Ontrack is
                not the seller but merely a facilitator of the sale and it’s
                financing.
              </li>
              <li class="body-2">
                The bike images displayed are for representation purpose only,
                actual product delivered may differ from the images seen on
                Ontrack’s application and website.
              </li>
              <li class="body-2">
                Model specifications are inspected at the time of manufacture by
                the original equipment manufacturer. Individual vehicle
                specification subject to vary.
              </li>
              <li class="body-2">
                Final loan approval is subject to providing necessary documents
                to the lender.
              </li>
              <li class="body-2">
                In case of purchase, the delivery of the vehicle shall be done
                by the tied-up dealership, and not Ontrack Technologies Private
                Limited. Any issue with the vehicle or it’s parts must be taken
                up directly by to the dealership or the original equipment
                manufacturer.
              </li>
              <li class="body-2">
                The loan amount mentioned on the Ontrack’s application may vary
                from the actual loan provided by the lender, this assessment
                will be carried out by the lender at their sole discretion and
                the responsibility of the communication will be borne by the
                lender.
              </li>
            </ol>
            <div class="my-4 pa-0 ma-0">
              <div class="my-4" style="text-align: center">
                <p class="ma-0 pa-0 body-2 text--disabled">
                  This is computer generated document, hence no signature
                  required.
                </p>
              </div>
              <v-col class="logo ma-0 pa-0">
                <img width="120px" :src="logo" alt="" />
              </v-col>
              <p class="body-1 ma-0 pa-0">
                <strong>Ontrack Technologies Pvt Ltd</strong>
              </p>
              <p class="ma-0 pa-0 body-2 text--disabled">
                3rd Floor, 9th Avenue Building, Indiranagar, Bangalore 560008
              </p>
            </div>
          </section>
        </div>
      </section>
    </vue-html2pdf>
    <v-card @click="generateReport" width="95%" class="text-center mx-auto pa-3 my-auto"
      >
      <div class="d-flex text-center justify-center">
        <v-icon small class="mr-2 color-p">far fa-file-download </v-icon>
        <p class="ma-0 pa-0 para-s">Confirmation Letter</p>
      </div>
    </v-card>
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          <p class="pt-3" style="color:white;">{{ message }}</p>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import VueHtml2pdf from "vue-html2pdf";
import logo from "../../../../assets/images/logo.png";
import moment from "moment";

export default {
  data() {
    return {
      logo: logo,
      loading: false,
      message: "Downloading",
    };
  },
  methods: {
    formatDate(date) {
      return moment(date).format("LL");
    },
    generateReport() {
      this.loading = true;
      try {
        this.$refs.html2Pdf.generatePdf();
      } finally {
        this.loading = false;
      }
    },
    onProgress(event) {
      if (event === 100) {
        this.loading = false;
      } else {
        this.loading = true;
        console.log(event);
      }
    },
    hasStartedGeneration() {
      this.loading = true;
      this.message = "Generating PDF";
    },
    hasGenerated() {
      this.loading = true;
      this.message = "PDF Generated";
    },
  },
  props: {
    loanInformation: Object,
    preferredDealer: Object,
    customerInformation: Object,
    assetInformation: Object,
  },
  components: {
    VueHtml2pdf,
  },
};
</script>
<style scoped>
.working-area {
  margin: 0 auto;
  padding: 10px 80px;
}
.body-2 {
  font-size: 0.875rem !important;
  font-weight: 400;
  letter-spacing: 0.0178571429em !important;
  line-height: 1.25rem;
  font-family: "Roboto", sans-serif !important;
}
.body-fine {
  font-size: 8px !important;
  font-weight: 400;
  letter-spacing: 0.0178571429em !important;
  line-height: 1.25rem;
  font-family: "Roboto", sans-serif !important;
}
.my-10 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.my-4 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.my-2 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
p {
  margin-bottom: 16px;
}
.text--disabled {
  color: rgb(219, 216, 216);
}
ul,
ol {
  padding-left: 24px;
}
li {
  padding-left: 24px;
}
.text--disabled {
  color: rgba(0, 0, 0, 0.38) !important;
}
.ma-0 {
  margin: 0px !important;
}
.pa-0 {
  padding: 0px !important;
}
</style>
